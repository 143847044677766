import React from 'react';
import './index.css';
import RepresentCharacters from '../../../../Components/Calculate/CalculateChar/Hooks';
import FullName from '../../../../Components/Calculate/CalculateChar';
import NumerologyData from '../../../../Components/Calculate/CalculateNumber';

const Chart = () => {
    const { characterArray } = FullName();
    const { day, month, year } = NumerologyData();
    const { chartName } = RepresentCharacters(characterArray);

    const chartNum = `${day}${month}${year}`;
    const chartFull = `${chartNum}${chartName}`;

    console.log('chartNum', chartNum);

    // Hàm đếm số trong một chuỗi
    const countNumbers = (numStr) => {
        const count = { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0 };
        for (let num of numStr) {
            if (count[num] !== undefined) {
                count[num] += 1;
            }
        }
        return count;
    };

    // Đếm số cho từng chuỗi
    const countedNumbersChartNum = countNumbers(chartNum);
    const countedNumbersChartName = countNumbers(chartName);
    const countedNumbersChartFull = countNumbers(chartFull);

    // Hàm tạo mảng grid numbers từ kết quả đếm
    const createGridNumbers = (countedNumbers) => [
        countedNumbers['3'] ? '3'.repeat(countedNumbers['3']) : '',
        countedNumbers['6'] ? '6'.repeat(countedNumbers['6']) : '',
        countedNumbers['9'] ? '9'.repeat(countedNumbers['9']) : '',
        countedNumbers['2'] ? '2'.repeat(countedNumbers['2']) : '',
        countedNumbers['5'] ? '5'.repeat(countedNumbers['5']) : '',
        countedNumbers['8'] ? '8'.repeat(countedNumbers['8']) : '',
        countedNumbers['1'] ? '1'.repeat(countedNumbers['1']) : '',
        countedNumbers['4'] ? '4'.repeat(countedNumbers['4']) : '',
        countedNumbers['7'] ? '7'.repeat(countedNumbers['7']) : '',
    ];

    const gridNumbersChartNum = createGridNumbers(countedNumbersChartNum);
    const gridNumbersChartName = createGridNumbers(countedNumbersChartName);
    const gridNumbersChartFull = createGridNumbers(countedNumbersChartFull);

    return (
        <div>
            <h3>TỔNG HỢP CÁC BIỂU ĐỒ QUAN TRỌNG</h3>
            <div className='grid-layouts'>
                <div className='boxgrid'>
                    <div className="grid-containerss">
                        {gridNumbersChartNum.map((num, index) => (
                            <div key={index} className="grid-itemss">
                                {num}
                            </div>
                        ))}
                    </div>
                    <div>
                        <p className='personal-year-chart'>BIỂU ĐỒ NGÀY SINH</p>
                    </div>
                </div>

                <div className='boxgrid'>
                    <div className="grid-containerss">
                        {gridNumbersChartName.map((num, index) => (
                            <div key={index} className="grid-itemss">
                                {num}
                            </div>
                        ))}
                    </div>
                    <div>
                        <p className='personal-year-chart'>BIỂU ĐỒ HỌ TÊN</p>
                    </div>
                </div>
            </div>
            <div className='boxgrid'>
                <div className="grid-containerss">
                    {gridNumbersChartFull.map((num, index) => (
                        <div key={index} className="grid-itemss">
                            {num}
                        </div>
                    ))}
                </div>
                <div>
                    <p className='personal-year-chart'>BIỂU ĐỒ TỔNG HỢP</p>
                </div>
            </div>
            <div class="image-container">
                <img src="https://hocvienivanga.vn/wp-content/uploads/2023/03/Bieu-do-khuc-giao-thoi-chu-ky-9-nam.jpg" alt="Biểu đồ" />
                <div>
                    <p className='personal-year-chart'>VẬN TRÌNH NĂM CÁ NHÂN</p>
                </div>
            </div>
            <div class="image-container">
                <img src="https://2kids.vn/thansohoc/giai-doan-cuoc-doi.png" alt="" />
                <div>
                    <p className='personal-year-chart'>3 GIAI ĐOẠN CUỘC ĐỜI</p>
                </div>
            </div>

        </div>
    );
};

export default Chart;
