import HookBlog from "../../Hook"
import { contentHeader, img } from "../../Content"
import { NummainContent } from "../../../../Components/Content/Report"
const ContentBlogNum22 = () => {
    const number = 22
    return (
        <>
        <HookBlog header={contentHeader[`num${number}`]} img={'https://s3-hn-2.cloud.cmctelecom.vn/tia-sang/2023/11/Anh-kham-pha-Hung-1170x700.jpg'} content={NummainContent[`num${number}`]} />
        </>
    )
}
export default ContentBlogNum22