import { useState, useContext } from "react";
import { DatePicker, Button, Input, Form } from "antd";
import { MyContext } from "../../../Route";
import { useNavigate } from "react-router-dom";
import "./form.css";
import PrimaryButton from "../../../Components/Button";
const FormItem = () => {
  const [form] = Form.useForm();
  const { setDate, setName } = useContext(MyContext);
  const navigate = useNavigate();

  const submitForm = (values) => {
    const newName = values.Name;
    const newDate = values.Date.format("YYYY-MM-DD");
    localStorage.setItem("savedName", newName);
    localStorage.setItem("savedDate", newDate);

    const saveName = localStorage.getItem("savedName");
    const saveDate = localStorage.getItem("savedDate");
    setDate(saveDate);
    setName(saveName);
    form.resetFields();
    navigate("/views");
  };

  return (
    <div className="container-fluid d-flex justify-content-center containerForm">
      <Form
        id="form-section"
        className="form col-md-8 d-flex justify-content-center"
        form={form}
        onFinish={submitForm}
        layout="vertical"
      >
        <div className="col-xl-12 col-lg-12 pt-5 pb-5 width85">
          <h2 className="text-center text-white">Thông Tin Của Bạn Là:</h2>
          <Form.Item
            label={<label className="labelForm">Họ Tên Đầy Đủ</label>}
            name="Name"
            rules={[{ required: true, message: "Vui lòng nhập họ tên đầy đủ!" }]}
          >
            <Input
              className="inputName input"
              placeholder="Ví dụ: Nguyễn Xuân A"
            />
          </Form.Item>
          <Form.Item
            label={<label className="labelForm">Ngày Tháng Năm Sinh</label>}
            name="Date"
            rules={[{ required: true, message: "Vui lòng chọn ngày tháng năm sinh!" }]}
          >
            <DatePicker className="inputDate input" />
          </Form.Item>
          <div className="pt-3">
            <PrimaryButton btn primary large type="submit">
              Xem Ngay
            </PrimaryButton>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default FormItem;
