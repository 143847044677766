const BalanceNumberContent = {
  num1: `Có rất nhiều điều để nói về việc tự tin vào bản thân và rút sức mạnh từ bên trong, nhưng bạn có lẽ cần mở lòng hơn và chia sẻ những khó khăn mà bạn đang đối mặt với những người thân yêu. Thói quen của bạn luôn là rút lui và đối mặt với những thách thức này một mình, nhưng sự cô lập đó có thể làm hại cho bạn. Hãy tìm kiếm lời khuyên từ người khác. Điều này chỉ giúp bạn mở rộ quan điểm hơn về các vấn đề. Không ai bảo bạn phải chấp nhận lời khuyên mọi lúc, nhưng việc tiếp xúc với các quan điểm khác nhau sẽ rất có lợi cho bạn. Bất kể tình hình hoặc cách bạn quyết định đối phó với nó, hãy học cách rút sức mạnh từ khía cạnh sáng tạo của bạn. Đôi khi, những giải pháp không truyền thống mới là những giải pháp tốt nhất.
    `,
  num2: ` Khéo léo và tế nhị là hai vũ khí tốt nhất của bạn trong tất cả các cuộc chiến cá nhân. Để qua khỏi cảm xúc. Chúng chỉ làm bạn chậm lại. Hãy tin tưởng vào bản thân và tin vào trực giác của bạn. Giải pháp sẽ xuất hiện nếu bạn chỉ tin vào khả năng nhìn thấy nó của mình. Đối mặt với vấn đề một cách mạnh mẽ. Đừng bao giờ thụ đầu hàng. Hãy làm việc để giảm thiểu càng nhiều vấn đề nổ bom trước khi chúng nổ. Bạn biết điều gì về việc ngăn ngừa một chút...  Đứng lên đối diện với vấn đề của bạn nhưng cũng sẵn sàng thỏa hiệp vì lợi ích của việc giải quyết xung đột. Giữ mọi thứ trong góc nhìn đúng đắn và đừng tạo ra vấn đề lớn hơn những gì cần tạo ra. Tính nhạy cảm của bạn có thể làm mọi thứ mất cân đối. Học cách giữ vững và tập trung. Bạn thu hút sự hài hòa và cân bằng như một phần của bản chất bạn, vì vậy hãy tận dụng điều này. Bạn có cơ hội trở thành người mang lại hòa bình cho những tình huống khó khăn. Sử dụng khả năng này cho sự thiện chí chung.
    `,
  num3: ` Tiếp cận các vấn đề và khó khăn với tinh thần lạc quan và một góc nhìn tích cực. Xem xét tác động lên tất cả các bên liên quan và tích cực theo đuổi giải pháp tốt nhất cho tất cả mọi người. Sử dụng sự từ chối và sự ảnh hưởng tự nhiên của bạn để điều hướng mọi thứ đến một giải pháp đồng thuận. Bạn có xu hướng để cảm xúc của bạn chạy trốn khỏi bạn khi đối mặt với khó khăn. Hãy cố gắng kiểm soát xu hướng này; bạn sẽ cần một góc nhìn khách quan, tai và trí óc, và lời khuyên nếu bạn muốn có kết quả tốt nhất. Đừng quá đầu tư cá nhân vào việc giải quyết vấn đề, nếu không bạn có thể bỏ lỡ giải pháp tốt nhất khi nó xuất hiện.
    `,
  num4: ` Hãy cố gắng không để những vấn đề có tác động về mặt cảm xúc ảnh hưởng quá nhiều đến bạn. Giữ cơn tức giận trong kiểm soát. Hãy nhớ rằng kỷ luật bản thân là đồng minh lớn nhất của bạn, vì vậy hãy học cách phát triển nó khi cuộc sống đơn giản để bạn có kỹ năng đó để sử dụng khi mọi thứ trở nên phức tạp. Tập trung vào hình dung tổng thể và hãy nhớ rằng trong cuộc sống luôn luôn có sự đánh đổi và nhượng bộ. Hãy nhớ rằng công lý luôn nên cân đối và điều hòa bằng lòng thương xót. Đừng giữ lại sự tha thứ và cố gắng nhìn những điều mà mọi người làm khiến bạn tức giận với một thái độ thông cảm. QUYẾT ĐỊNH rằng bạn sẽ hiểu và đồng cảm. Luôn sẵn sàng bước vào tình thế của người khác. Hãy cố gắng xem tình huống từ góc độ của đối phương và sẵn sàng nhìn vào điều ĐANG DIỄN RA, không phải những gì bạn cảm nhận. Luôn có các khía cạnh của mỗi vấn đề mà chúng ta tự nhiên muốn bỏ qua hoặc làm việc xung quanh. Hãy sẵn sàng đối mặt với chúng và giải quyết chúng.
    `,
  num5: ` Tập trung vào các vấn đề của bạn. Dành sự chú ý đầy đủ cho chúng. Điều tồi tệ nhất bạn có thể làm là tránh chúng. Bạn có xu hướng tìm kiếm những sự xao lãng để đỡ tâm trí khỏi các vấn đề của bạn như một cơ chế phòng vệ. Hãy trở nên sẵn sàng để bỏ bảo vệ của bạn và để mọi thứ diễn ra. Bạn có thể có xu hướng bám vào những thứ như rượu, ma túy hoặc những thói quen khác để cảm thấy an toàn, vì vậy hãy để ý các dấu hiệu cảnh báo mà những hành vi này có thể nảy sinh. Bạn hoàn toàn có khả năng tìm ra giải pháp cho những vấn đề của mình, ngay cả khi điều đó có nghĩa bạn phải sáng tạo. Bạn hiểu về vấn đề và có thể nhìn thấy cách vượt qua chúng tốt hơn hơn bạn nghĩ.
    `,
  num6: ` Bạn nhìn thấy xa hơn bề mặt của mọi thứ và dễ dàng nhận ra nguồn gốc của xung đột. Vấn đề là bạn giỏi nhất ở việc xác định nó hơn là giải quyết nó, vì vậy bạn có xu hướng trì hoãn khá nhiều cho bạn bè và gia đình. Bạn tìm kiếm lời khuyên từ người khác và cũng để nhận sự an ủi khi áp lực trở nên quá nhiều. Bạn cũng có xu hướng né tránh sự xung đột như một cơ chế phòng vệ. Điều quan trọng là nếu bạn liên quan đến vấn đề, bạn phải chịu trách nhiệm ít nhất một phần trong việc giải quyết xung đột. Hãy tìm cách chấp nhận và đối phó với nó.
    `,
  num7: ` Bạn có xu hướng rút lui vào một nơi trong tâm trí bạn cảm thấy an toàn để tránh đối mặt với vấn đề và khó khăn. Vấn đề thực sự là bạn đang đánh giá thấp khả năng của chính mình để giải quyết mọi thứ. Bạn có khả năng vượt xa để nhìn thấy giải pháp và thực hiện nó, nhưng sự tự nghi ngờ của bạn làm chậm quá trình một cách chóng mặt. Vấn đề ở đây không phải là xung đột chính nó. Vấn đề thực sự là bạn. Học cách đối mặt với sự nghi ngờ và ngăn chặn của chính mình. Đừng cố gắng đe dọa chúng để đẩy chúng vào sự ngoan cường, chỉ cần nhận thức về chúng và đảm bảo rằng mọi thứ đều dưới sự kiểm soát. Bạn sẽ ngạc nhiên khi thấy mọi thứ sẽ nhanh chóng trở nên ổn thỏa khi bạn làm điều đó.
    `,
  num8: ` Bạn có rất nhiều sức mạnh và khả năng tự nhiên trong một lĩnh vực cụ thể. Không nghi ngờ gì về điều này. Giờ đã đến lúc tìm cách sử dụng nó để đạt được sự cân bằng trong tất cả các khía cạnh của cuộc sống, bao gồm cả việc giải quyết xung đột. Khuynh hướng của bạn là thao túng. Điều này cần thay đổi. Có một sự khác biệt lớn giữa ảnh hưởng và thao túng. Bạn có khả năng thực hiện điều trước nên hãy dừng việc dùng cách sau. Điều này là vấn đề tự tin của bạn. Đừng để sợ hãi, lo âu và tự nghi ngờ cản trở bạn khỏi hoạt động trong tiềm năng đầy đủ của mình.  Một phần của việc bạn là người bạn là và có những khả năng bạn sở hữu là khả năng đảm nhận trách nhiệm trong xung đột, dù nó bắt đầu từ bạn hay không. Bạn có khả năng, giờ hãy làm việc với ý muốn. Bạn là người tư duy sáng tạo và điều này có nghĩa bạn cũng có thể đưa ra những giải pháp không thông thường nhưng rất hiệu quả cho hầu hết các vấn đề. Hãy theo trực giác và đảm nhận vấn đề. Khả năng có lẽ bạn là người duy nhất có thể tạo ra một giải pháp khả thi. Bạn có khả năng làm nhiều điều tốt hoặc gây nhiều thiệt hại với khả năng của bạn. Hãy lựa chọn điều tốt. Điều này có thể không luôn luôn là quyết định dễ dàng, nhưng những phần thưởng, cả về mặt cá nhân và đối với người khác, sẽ vượt xa những khó khăn.
    `,
  num9: ` Lắng nghe những người đang trong xung đột hoặc đang trải qua những khó khăn giống bạn. Khả năng thông cảm của bạn là điều sẽ giúp bạn giải quyết vấn đề. Bạn có khả năng hiểu những gì mọi người đang trải qua, ngay cả khi bạn chưa từng ở trong vị trí đó. Khi bạn nói, "Tôi biết cảm giác của bạn...", điều đó là một sự thật đen tối. Bạn chỉ đơn giản là biết. Vấn đề ở đây là bạn cũng có xu hướng coi thường những người đang gặp khó khăn với một thái độ hơi kiêu căng. Đây là thời điểm để bạn tìm cách kết nối với người khác một cách chân thành hơn, thể hiện sự tôn trọng và đồng cảm thay vì sự phân biệt. Hãy cân nhắc tới tình hình từ góc độ của họ và thể hiện lòng biết ơn cho những điều bạn có thay vì tự mãn về chúng.
    `,
  num11: ` Số 11 là một trong các "số Master" trong số học, cùng với 22 và 33. Số này thường được liên kết với tình spiritual, sự nhạy bén tâm linh và khả năng tiếp cận thông tin từ nguồn cao cấp. Số 11 thường được xem như một con số mang lại sự chấp nhận của sứ mệnh tâm linh và khám phá đường dẫn đến sự mở cửa tinh thần. Nếu bạn cảm thấy gắn kết với số 11, điều quan trọng là hãy tìm hiểu và phát triển tài năng tâm linh của mình. Hãy tập trung vào việc hiểu rõ nguồn gốc của suy nghĩ và cảm xúc, và tìm cách sử dụng sự nhạy bén của bạn để đạt được sự bình yên và sự thấu hiểu sâu xa. Hãy tìm kiếm những trải nghiệm tâm linh và thấu hiểu rõ hơn về sứ mệnh của bạn trong cuộc sống.
    `,
  num22: ` Tương tự như số 11, số 22 cũng là một số Master và thể hiện sức mạnh tâm linh và tiềm năng sự hiện thực hóa ý tưởng lớn. Nó thường được xem như một con số của kiến thức thực tế và khả năng xây dựng cơ sở vững chắc để thực hiện ước mơ lớn. Nếu bạn liên kết với số 22, hãy tìm cách kết hợp sự sáng tạo và kiến thức thực tế để tạo ra những dự án và giải pháp có thể thay đổi thế giới. Hãy tập trung vào việc xây dựng nền tảng vững chắc cho những ý tưởng lớn của bạn và hãy dũng cảm đối mặt với những thách thức khó khăn. Hãy tin tưởng vào khả năng thực hiện ý tưởng của bạn và không ngừng học hỏi để nâng cao kiến thức và kỹ năng của mình.
    `,
};
export default BalanceNumberContent;
