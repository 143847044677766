import HookBlog from "../../Hook"
import { contentHeader, img } from "../../Content"
import { NummainContent } from "../../../../Components/Content/Report"
const ContentBlogNum6 = () => {
    const number = 6
    return (
        <>
        <HookBlog header={contentHeader[`num${number}`]} img={'https://genk.mediacdn.vn/thumb_w/660/139269124445442048/2020/7/17/photo-1-1594983770063472991059.jpeg'} content={NummainContent[`num${number}`]} />
        </>
    )
}
export default ContentBlogNum6