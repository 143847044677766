const PersonalityNumberContent = {
  num1: `
       Bạn thích mở đường riêng cho mình; bạn biết rằng bạn là người chủ của số phận của mình. Bạn có một tính cách năng động và năng động. Bạn có sức hấp dẫn đặc biệt nhưng cũng rất bình tĩnh và kiểm soát. Bạn đối mặt với vấn đề một cách trực tiếp và xử lý xung đột tốt. Khi bạn sai hoặc để người khác thấy bất kỳ mức độ yếu đuối nào, bạn không thích điều đó nhưng bạn vẫn duy trì thái độ trang trọng. Bạn học tốt từ những sai lầm của mình và cố gắng giúp người khác làm điều tương tự. Bạn có khả năng thích mặc bộ đồ vest hơn là bộ đồ tắm, nhưng với phong cách nghiêm túc của bạn đi kèm với sự sành điệu về màu sắc. Bạn không phiền bị chú ý. Bạn có gu thời trang tốt nhưng cũng giữ được phong cách riêng của mình.
 Bạn cố gắng làm cho diện mạo của mình ở một mức độ nhất định. Bạn sợ sẽ không được mọi người yêu thích hoặc tôn trọng nếu bạn trở nên thừa cân. Nếu bạn đã thừa cân rồi, khả năng là bạn dự đoán người khác sẽ phê phán bạn một cách quá mức, điều này có thể làm suy yếu tự trọng của bạn. Đừng để những điều không hoàn hảo theo bạn tạo nên hình ảnh bản thân. Nếu bạn có khả năng thay đổi những điều bạn không thích về bản thân, hãy làm điều đó. Nếu không, bạn cần học cách chấp nhận điều đó.
      
    `,
  num2: `
       Thái độ thân thiện và thân mật của bạn là điểm thu hút đáng mong đợi nhất. Mọi người cảm nhận bạn là người ấm áp, dễ thân thiện và dễ tiếp cận. Bạn giữ gìn bản thân mình được chăm sóc tốt và mặc đẹp. Bạn thích trông tốt nhưng cũng thoải mái. Giống như tính cách của bạn, bạn thích vải mềm mại và không gò bó. Bạn rất đam mê và là một người tình hết mình. Bạn duy trì tư duy tích cực bằng cách cam kết đến sức khỏe và sự phát triển cá nhân và bằng cách cống hiến tất cả vào công việc bạn làm.
  Bạn có thừa kiên nhẫn và không dễ bị xao lạc. Bạn chậm nóng và nhanh chóng tha thứ. Bạn cố gắng không bao giờ ghi chép những điều xấu vào sổ. Bạn hồi phục khi bị người khác làm tổn thương và cố gắng hiểu hơn là phán đoán. Bạn không thích xung đột và thấy các cuộc tranh luận và đánh nhau là mất năng lượng. Bạn có thể đã lớn lên với những bậc cha mẹ siêu phê phán, người đã dạy bạn nhiều về cách không nhìn nhận và tương tác với người khác, và bạn đã áp dụng những bài học tình cờ này một cách rất hiệu quả. Bạn có một luồng tình cảm và tính cách yếu đuối dưới lòng tốt khiến mọi người tin tưởng bạn.
      
    `,
  num3: `
       Hầu hết những người có Số Tính Cách là 3 có vẻ đẹp xuất sắc. Khi nó xuất hiện trong Số Tính Cách Bên Ngoài, thường biểu thị vẻ đẹp về mặt hình thể. Trong số nhỏ thì biểu thị vẻ đẹp nội tại và sự tự tin trong vẻ bề ngoại.
 Bạn toát ra sự quyến rũ và sự sôi động. Mọi người nói rằng bạn tràn đầy sự sống. Có thể bạn cũng là một người lãng mạn không hy vọng. Bạn yêu thương dễ dàng nhưng cũng dễ dàng rời xa. Đặc điểm này có thể gây hỗn loạn với cảm xúc của bạn và của đối tượng của tình cảm nếu không kiểm soát tốt. Ổn định cuộc sống và duy trì mối quan hệ tình yêu có ý nghĩa không dễ dàng cho bạn, nhưng học cách hoạt động trong những giới hạn này sẽ giúp bạn nhiều trong lĩnh vực cảm xúc.
 Cuối cùng, khi cơ hội gõ cửa, hãy mở cửa. May mắn, thành công và thịnh vượng dường như luôn ưa thích bạn, nên đừng cản trở chúng!
      
    `,
  num4: `
       Mọi người coi bạn là người đáng tin cậy và đáng tin. Họ tin tưởng quyết định của bạn và tìm bạn khi họ cần lời khuyên. Bạn đại diện cho sự ổn định đối với mọi người và trong các nỗ lực chuyên nghiệp, bạn là người mà đội của bạn ngưỡng mộ như ví dụ về thành công và nguồn cảm hứng, ý tưởng và chiến lược tốt. Bạn chăm chỉ duy trì những gì bạn tin là quan trọng nhất và ủng hộ tính chất đáng kính nhất của bạn. Bạn là người rất chính xác và toát ra thái độ và thái độ cho biết bạn đang kiểm soát tình thế.
 Bạn trân trọng chất lượng nhưng bạn cân nhắc mong muốn chất lượng tốt với giá trị tốt, đặc biệt trong quần áo và tài sản của bạn. Bạn không bao giờ chi tiêu quá mức chỉ để duy trì vẻ bề ngoại, nhưng đôi khi bạn có thể hoang phí để thưởng thức bản thân hoặc duy trì tâm trạng tốt. Cuối cùng, dù sao, bạn nhận ra giá trị của tiền bạc và cố gắng tiêu ít và tiết kiệm nhiều khi có thu nhập dư. Nếu bạn dự định hoang phí vào bất cứ điều gì, hãy làm cho nó thật tốt! Một chiếc cà vạt lụa màu sắc hay một món trang sức mới sẽ tăng cường giá trị bản thân cũng như tạo dựng hình ảnh tổng thể của bạn.
      
    `,
  num5: `
       Mọi người thấy bạn rất kích thích. Tại các buổi tiệc và gặp gỡ, bạn luôn tạo dấu ấn cho dù có ý định như vậy hay không. Mọi người luôn vui mừng khi gặp bạn và trân trọng sự hài hước của bạn. Bạn có thái độ thư thái và thảnh thơi khiến mọi người thư giãn khi ở bên bạn. Tính thần lạc quan và tích cực của bạn là lây nhiễm. Chúng khiến mọi người muốn ở bên bạn và mô phỏng hành động và thái độ của bạn. Mọi người thấy bạn là nguồn cảm hứng.
   Nếu có bất kỳ lý do gì để bạn phải cẩn trọng, đó là xu hướng tự thoả mãn một chút. Điều này bao gồm bất cứ điều gì kích thích bạn từ cảm xúc đến vật chất, nhưng đặc biệt là cái sau. Bạn có xu hướng muốn trải nghiệm mọi vị kỹ năng, từ thực phẩm, đồ uống, ma túy và tình dục. Việc duy trì disclipine cá nhân cao sẽ cần phải được trau dồi để tránh tự hủy hoại không cố ý.
  Bạn là người quyến rũ bẩm sinh, điều này cũng có thể làm bạn dễ bị tự thỏa mãn với những khao khát thú thúc cá nhân của mình. Dễ dàng cho bạn thu hút nhiều người yêu như bạn có thể quản lý, nhưng hãy cẩn trọng vì việc trải rộng cảm xúc và tình cảm của bạn có thể làm tổn hại đến danh tiếng của bạn với sự nhấn mạnh vào tự thỏa mãn và tự mưu cầu. Một chút tự kiểm soát sẽ giúp bạn rất nhiều.
      
    `,
  num6: `
       Bạn có một tài năng về lòng từ bi thực sự, vì vậy bạn cũng thu hút những người đang cần sự an ủi hoặc một vai để dựa vào. Bạn quan tâm đến vấn đề của họ và cùng họ chia sẻ khi họ đau khổ hoặc trải qua bất công. Nhiều người số 6 cũng là những người cảm xúc thấu hiểu những người thực sự cảm nhận được cảm xúc của người khác, đặc biệt là các tình cảm về buồn bã, lo lắng, sợ hãi hoặc tang thương. Vì vậy, việc bảo vệ cảm xúc và tình cảm của chính bạn rất cần thiết.
    Bạn cũng cần duy trì tâm trí trong việc giao tiếp với mọi người. Bạn rất hào phóng và tiếng về việc giúp đỡ người khác có thể lan truyền như lửa rừng trong những vòng tròn không đúng. Có những người sẽ cố gắng lợi dụng bạn, đặc biệt khi liên quan đến tiền bạc. Hãy cảnh giác khi người khác chia sẻ về khó khăn tài chính của họ. Khả năng bị kêu gọi cho vay tiền là rất cao.
 Bạn là một người lãng mạn không hy vọng nhưng cũng rất trung thành. Bạn luôn muốn tìm cách ổn định nếu bạn chưa làm điều đó. Nếu bạn đã kết hôn, khả năng bạn là yếu tố ổn định tuyệt vời trong mối quan hệ của bạn. Bạn trung thành và bảo vệ. Khi đối với con cái, bạn chính là hình mẫu bảo vệ hoàn hảo nhưng bạn cũng tôn trọng nhu cầu không gian của chúng. Bạn yêu với tất cả tấm lòng nhưng cũng cố gắng để không át hơi sống chết.
 Bạn có khả năng đặc biệt trong việc đánh giá nghệ thuật. Bạn là người yêu âm nhạc trên rất nhiều thể loại và bạn thích các buổi hòa nhạc trực tiếp và kịch. Bạn tự hào về gu thẩm mỹ của mình trong những điều này và thích chia sẻ chúng với người khác.
      
    `,
  num7: `
       Mọi người thấy bạn là người bí ẩn. Điều này không nhất thiết phải là điều xấu; mọi thứ phụ thuộc vào cách bạn tiếp cận. Hãy để mọi người thấy tính độc lập và khả năng tự túc của bạn. Đừng sợ hiển thị trí tuệ của bạn nhưng hãy chắc chắn tạo điểm chung với người khác. Điều này sẽ tạo sự hấp dẫn cho tính bí ẩn của bạn thay vì gây ác cảm. Bạn có thể không bao giờ có danh tiếng là người ấm áp hay thông cảm, nhưng mọi người không thể không tôn trọng bạn.
 Bạn có các niềm tin tâm linh cụ thể và bạn rất nghiêm túc về chúng. Bạn đem đến nhiều góc nhìn độc đáo khi thảo luận về tâm linh hoặc thần học và quan điểm của bạn khiến mọi người phải suy nghĩ về niềm tin của họ.
 Mặt trái của tất cả điều này là, mặc dù nhiều người có thể kính trọng bạn, họ cũng thấy bạn khó tiếp cận. Họ thường không thể làm nhiều bước tiến để hiểu bạn, thay vào đó chỉ cảm thấy thoải mái khi biết về bạn. Hãy cẩn trọng để không trở nên kiêu căng hoặc tự ái. Đây là một bẫy dễ dàng mà những người có Số Tính Cách là 7 có thể rơi vào.
      
    `,
  num8: `
       Sức mạnh tinh thần bên trong của bạn rất đáng kể nhưng hãy cẩn trọng về cách điều này được thể hiện bởi người khác. Bạn có thể thậm chí không biết mức ảnh hưởng mà hành động và tính cách của bạn có thể tạo ra, nhưng bạn nắm giữ khả năng xây dựng hoặc phá hủy bằng lời nói và hành động của bạn. Điều này đúng trong tất cả giao tiếp với mọi người, từ giao dịch kinh doanh đơn giản đến mối quan hệ nghiêm trọng với người khác, người yêu, vợ chồng, con cái hoặc gia đình mở rộng. Bạn có xu hướng rất hào phóng nhưng cũng có xu hướng đánh giá tình hình, thái độ và tính xác thực của một người trước khi quyết định giúp đỡ họ.
  Hãy chú ý đến diện mạo của bạn. Ống áo để gây ấn tượng. Tủ quần áo đúng có thể tạo hiệu ứng cân đối đáng kể đối với khả năng bạn điều khiển các tình huống và khía cạnh khác nhau của cuộc sống. Hãy cố gắng trở nên dễ tiếp cận hơn và học cách chấp nhận xu hướng của người khác để giao việc khó khăn cho bạn. Họ làm điều đó vì họ biết bạn có thể xử lý những điều mà họ không thể. Sự tự tin của bạn có thể làm giảm đi lòng tự trọng của người khác, vì vậy hãy cẩn trọng để không vô tình làm giảm sự tự tôn của người khác. Hãy cam kết làm việc trong giới hạn của chính mình và nhận ra rằng giới hạn của bạn có thể rộng hơn so với người khác. Nhận thức rằng điều này là hoàn toàn bình thường và biết khi nào nên bước lui và tạo cơ hội cho người khác mở rộ và phát triển. Bạn sẽ cảm thấy thoải mái hơn xung quanh họ nếu họ được phép làm điều đó trước mặt bạn.
      
    `,
  num9: `      Bạn có xu hướng đứng thẳng và tự hào. Bạn tự tin vào tài năng và khả năng của mình. Nhiều diễn viên, nhạc sĩ và nghệ sĩ khác thường có Số Tính Cách là 9. Bạn giành được sự ngưỡng mộ của mọi người thông qua việc phát triển và thể hiện tài năng của mình. Mặt trái là tài năng của bạn có thể gây ra sự đố kỵ và ghen tị ở người khác. Họ sẽ có xu hướng không thích bạn dựa trên không tự tin của chính họ. Hãy cảnh giác: đố kỵ gần như luôn luôn dẫn đến sự độc ác và căm phẫn. Đừng để bất kỳ ai có lý do muốn bạn làm kẻ thù của họ.
 Không cách nào để tránh khỏi việc bạn sẽ được coi là một chút kiêu căng. Khả năng tắt điều này gần như không có, vì vậy thay vào đó bạn sẽ phải cách riêng để biện minh cho nó bằng cách liên tục cải tiến và tái tạo bản thân. Đồng thời, bạn cũng cần phát triển thái độ khiêm tốn để giữ cho bản thân mình luôn cởi mở. Đừng bắt đầu tin vào đám đông. Hãy tìm kiếm các khía cạnh cần cải thiện và đặt ra mục tiêu để làm cho những cải thiện đó. Điều này không nên quá khó khăn vì bạn cũng là người yêu thương từ trong tâm hồn. Bạn thích để người khác cũng trải nghiệm một phần của thành quả của bạn và những hành động nhân ái giúp xây dựng lòng tự tôn của bạn.
      
    `,
  num11: `  Bạn là người mang trong mình sự kết hợp giữa tinh thần của Số 1 và Số 2. Điều này đem đến cho bạn một sự kết hợp độc đáo của động lực và sự nhạy cảm. Bạn có một tầm nhìn sâu sắc và thường có khả năng thấu hiểu sâu xa về người khác và tình huống. Bạn là người đa tài và thường có thể thể hiện khả năng sáng tạo trong nhiều lĩnh vực.
 Tuy nhiên, bạn cũng có thể đối mặt với những thách thức trong việc tự quản lý sự nhạy cảm của mình. Bạn có thể dễ dàng bị ảnh hưởng bởi cảm xúc và tình huống xung quanh. Điều này có thể dẫn đến sự dao động tâm trạng và khả năng mất cân bằng. Hãy cố gắng duy trì một sự ổn định tinh thần bằng cách học cách tự bảo vệ khỏi ảnh hưởng tiêu cực.
  Bạn có tiềm năng lớn trong việc thúc đẩy sự thấu hiểu và tương tác tích cực trong các môi trường xã hội. Khả năng tương tác xã hội của bạn có thể đem lại những ý tưởng sáng tạo và những giải pháp mới mẻ cho các vấn đề phức tạp.
      
    `,
  num22: `
       Bạn là người mang trong mình sự kết hợp giữa tinh thần của Số 2 và Số 4. Điều này biểu thị cho sự kết hợp giữa sự nhạy cảm và sự kiên nhẫn. Bạn có tầm nhìn xa hơn và thường nhìn thấy các khía cạnh khác biệt và sâu sắc trong mọi tình huống. Khả năng phân tích của bạn là mạnh mẽ và bạn thường có khả năng xây dựng kế hoạch chi tiết và thực hiện chúng một cách kiên trì.
 Bạn cũng có tình cảm và sự nhạy cảm đặc biệt đối với nhu cầu của người khác. Điều này làm cho bạn trở thành một người lãnh đạo xuất sắc trong việc hỗ trợ và giúp đỡ người khác. Tuy nhiên, hãy cẩn trọng để không bị quá chìm trong việc chăm sóc người khác mà bỏ lơi chính bản thân bạn.
 Bạn có khả năng tạo ra những tác động lớn trong xã hội và công việc. Với tầm nhìn sâu sắc và khả năng thiết kế kế hoạch chính xác, bạn có thể đóng góp đáng kể cho những dự án lớn và có tầm ảnh hưởng.
      
    `,
};
export default PersonalityNumberContent;
