import HookBlog from "../../Hook"
import { contentHeader, img } from "../../Content"
import { NummainContent } from "../../../../Components/Content/Report"
const ContentBlogNum1 = () => {
    const number = 1
    return (
        <>
        <HookBlog header={contentHeader[`num${number}`]} img={'https://phunuphapluat.nguoiduatin.vn/uploads/2022/12/13/3-chom-sao-truc-giac-nhay-ben-nhin-thau-su-doi-du-khong-noi-ra-thien-binh-1670888437-111-width696height492.jpg'} content={NummainContent[`num${number}`]} />
        </>
    )
}
export default ContentBlogNum1