import HookBlog from "../../Hook"
import { contentHeader, img } from "../../Content"
import { NummainContent } from "../../../../Components/Content/Report"
const ContentBlogNum2 = () => {
    const number = 2
    return (
        <>
        <HookBlog header={contentHeader[`num${number}`]} img={'https://vcdn1-giadinh.vnecdn.net/2013/11/12/balancing-life-4636-1384272163.png?w=680&h=0&q=100&dpr=1&fit=crop&s=vwEdg01DKMMoKoXi4C64Kg'} content={NummainContent[`num${number}`]} />
        </>
    )
}
export default ContentBlogNum2