import React from 'react';

const TextCircle = {
    num10: <p>22 là một trong bộ ba số quan trọng trong Nhân số học (11, 22, 33). Theo đó, cả 3 số này được liên kết với nhau và tạo thành “Tam giác của sự khai sáng” (theo tiếng Anh là Triangle of Enlightenment). Mỗi con số trên đều mang một biểu tượng lớn và nhiều ý nghĩa nhỏ đặc thù. Những người mang vận mệnh thần số học số 22 thường có tham vọng lớn, khát khao phát triển cuộc đời. Đặc biệt, họ luôn có tố chất, phong thái của nhà lãnh đạo thông minh, tài ba.</p>,
    num0: <p>Thần số học số 1 là hiện thân của sự táo bạo, đổi mới, chấp nhận rủi ro, khả năng phục hồi và đi theo trái tim mình. Nhân số học số 1 giúp phát triển sự sáng tạo và sự tự tin của bạn trong mọi khía cạnh của cuộc sống. Mục đích sống của những người có số chủ đạo 1 là mang tới năng lượng sáng tạo tích cực, đạt được sự độc lập trong các mối quan hệ của bản thân.</p>,
    num1: <p>Thần số học số 2 là hiện thân cho sự kiên nhẫn, công bằng, khả năng ngoại giao và một tấm lòng yêu thương, nhân ái. Nhiệm vụ của số chủ đạo 2 là trở thành người kết nối, hòa giải, đem tình yêu thương vào các mối quan hệ trong mọi khía cạnh cuộc sống. Mục đích sống của những người này là học được cách làm việc hài hòa, cân bằng và tôn trọng lẫn nhau.</p>,
    num2: <p>Thần số học số 3 là hiện thân của sự năng động, vui tươi, khả năng cân bằng cảm xúc và truyền được nhiều nguồn cảm hứng. Nhiệm vụ của những người mang số 3 trong Thần số học là người sử dụng cách diễn đạt sáng tạo, phát triển khả năng kết nối, tình cảm lành mạnh trong mọi khía cạnh của cuộc sống. Mục đích sống của những người này là sử dụng cảm xúc, tài năng sáng tạo của mình để nâng cao tinh thần và truyền cảm hứng tới mọi người.</p>,
    num3: <p>Thần số học số 4 là hiện thân cho những định hướng, sự chia sẻ thực tế, làm việc chỉn chu. Nhiệm vụ của bạn là tạo ra sự ổn định và an toàn khi cần hoàn thành một điều gì đó mang giá trị lâu dài. Mục đích của những người này là tạo nên sự bền vững trong quá trình hướng tới mục tiêu cuộc đời. Việc mang lại sự an toàn cho chính mình và mọi người là điều mà nhân số học số 4 khao khát mạnh mẽ.</p>,
    num4: <p>Thần số học số 5 là hiện thân cho sự mạo hiểm, bản tính thích phiêu lưu, không sợ hãi và muốn truyền tải tới mọi người cách sống tự do. Nhiệm vụ của số 5 trong thần số học là phát triển sự tự do theo thiên hướng xây dựng, bạn vừa có kỷ luật vừa có mong muốn trải nghiệm những điều tốt đẹp cuộc sống mang lại. Mục đích cuộc sống của bạn là tìm thấy sự tự do bên trong những quy tắc, luật lệ và trải nghiệm cuộc sống.</p>,
    num5: <p>Thần số học số 6 là hiện thân cho trách nhiệm, không phán xét, không chỉ trích những hành động và lựa chọn của người khác, bạn có tầm nhìn xa và khả năng nhìn thấy toàn cảnh trước mắt. Nhiệm vụ của thần số học số 6 là phát triển và trau dồi khả năng nhìn xa trông rộng, cũng như học cách chấp nhận cuộc sống đa chiều. Mục đích sống của nhân số học số 6 là dung hòa lý tưởng cao đẹp với những thứ khiếm khuyết, không ngừng bồi bổ thêm tình yêu thương trong mọi việc bạn đã, đang và sẽ làm.</p>,
    num6: <p>Thần số học số 7 là hiện thân của trực giác, trí tuệ, không ngại mở lòng với người khác. Nhiệm vụ của thần số học số 7 là phát triển niềm tin vào dòng chảy của cuộc sống, mở ra sự phát triển và nhận thức cho các giá trị cá nhân và tinh thần. Mục đích cuộc sống của bạn là tin tưởng vào bản thân, vào quá trình bạn cảm thấy an toàn để có thể mở lòng và chia sẻ trí tuệ bên trong của mình ra thế giới.</p>,
    num7: <p>Thần số học số 8 là hiện thân cho sự dư dả về tài chính, sử dụng quyền lực khôn ngoan, luôn dành nhiều thời gian, tiền bạc và sức ảnh hưởng của mình để làm thế giới tốt đẹp hơn. Nhiệm vụ của số 8 trong nhân số học là tạo ra sự dồi dào về vật chất. Mục đích cuộc sống của bạn là sử dụng ảnh hưởng, quyền lực tài chính của mình để tạo ra dấu ấn trên thế giới và giúp đỡ những người khác.</p>,
    num8: <p>Thần số học số 9 là hiện thân của người lắng nghe, biết đồng cảm và cởi mở trong cuộc sống. Nhiệm vụ của nhân số học số 9 là trở thành người chính trực trên con đường theo đuổi sự sáng tạo và cống hiến. Mục đích của bạn là sống với các tiêu chuẩn chính trực, sắp xếp cuộc sống song song giữa trí tuệ và trái tim, truyền cảm hứng cho mọi người.</p>,
    num9: <p>Thần số học số 11 nhận được món quà thượng đế ban cho bạn là trực giác rất phong phú. Bạn tốt bụng và bạn là người giỏi gìn giữ hòa bình. Nhân số học số 11 có khả năng phát hiện các xu hướng mới và vượt qua các ranh giới cố hữu. Bạn cũng có thể bị lôi kéo và có khả năng cực đoan. Bài học dành cho bạn là tính quyết đoán và kiên trì, không từ bỏ ước mơ của mình.</p>,
}
export default TextCircle