import "bootstrap/dist/css/bootstrap.css";
import "../homepage.css";
import PrimaryButton from "../../../Components/Button";
import Form from "../Form";
const Banner = () => {
    return (
        <div className="homepageContainer container-fluid">
            <div className="row banner justify-content-center flex-md-row">
                <div className="flex-column d-flex align-items-center">
                    <h3 className="p-2 text-white fw-bold mt-3 text-center">
                        BÁO CÁO THẦN SỐ HỌC
                    </h3>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h3 class="animate-charcter"> MIỄN PHÍ 100%</h3>
                            </div>
                        </div>
                    </div>
                    <p className="p-2 text-white fw-bold mt-3 text-center">
                        Nhập tên và ngày sinh của bạn
                        <br />
                        <span className="quote">Để luận giải sự nghiệp, vận mệnh, tài năng, dự báo tương lai.</span>
                    </p>
                </div>
                <Form />
            </div>
        </div>
    );
};

export default Banner;
