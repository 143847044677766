import NummainRp from "../../../Report/Calculate/Report/Nummain";
import BirdDayNumberRp from "../../../Report/Calculate/Report/BirdDayNumber";

import FullName from "../../../../Components/Calculate/CalculateChar";
import NumerologyData from "../../../../Components/Calculate/CalculateNumber";

import "bootstrap/dist/css/bootstrap.css";

const DemoRpPayment = () => {
    const { numMain, day, month, year } = NumerologyData();
    const { normalizedFullName } = FullName();
    return (
        <div className="pb-3">
            <div className="bgFixed pb-5">
                <div>
                    <p className="text-white pt-5 d-flex">
                        Tên của bạn là:
                        <p className="text-uppercase">{normalizedFullName}</p>
                    </p>
                </div>
                <div>
                    <p className="text-white d-flex">
                        Sinh ngày: {day}/{month}/{year}
                    </p>
                </div>
                <div>
                    <p className="text-white fs-3">Số chủ đạo của bạn là: </p>
                </div>
                <div className="text-white col-sm-4 col-md-4 col-xl-4 col-12 containerRoute">
                    <div className="border__bg"></div>
                    <div className="backgroungCanvas"></div>
                    <div className="numMain">{numMain}</div>
                </div>
                <div className="col-sm-10 col-md-8 col-xl-8 col-12">
                    <div className="containerContentDemoRp">
                        {" "}
                        <NummainRp />{" "}
                    </div>

                    <div className="containerContentDemoRp">
                        {" "}
                        <BirdDayNumberRp />{" "}
                    </div>

                </div>

            </div>
            {/* <div><i className="text-danger"><u>Vui lòng thanh toán để được xem báo cáo đầy đủ chi tiết...</u></i></div> */}
        </div>
    );
};
export default DemoRpPayment;
