import HookBlog from "../../Hook"
import { contentHeader, img } from "../../Content"
import { NummainContent } from "../../../../Components/Content/Report"
const ContentBlogNum1 = () => {
    const number = 1
    return (
        <>
        <HookBlog header={contentHeader[`num${number}`]} img={'https://coffeehr.com.vn/wp-content/uploads/2023/01/to-chat-lanh-dao.jpg.webp'} content={NummainContent[`num${number}`]} />
        </>
    )
}
export default ContentBlogNum1