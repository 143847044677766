import "./img.css";
const ImgFeedback = () => {
  return (
   
      <div className="container-img">
        <img src="Assets/Images/Seeding/Screenshot_1.jpg" alt="" />
        {/* <img src="Assets/Images/Seeding/Screenshot_3.jpg" alt="" /> */}
        <img src="Assets/Images/Seeding/Screenshot_4.jpg" alt="" />
        <img src="Assets/Images/Seeding/Screenshot_5.jpg" alt="" />
        <img src="Assets/Images/Seeding/Screenshot_6.jpg" alt="" />
        <img src="Assets/Images/Seeding/Screenshot_7.jpg" alt="" />
        {/* <img src="Assets/Images/Seeding/Screenshot_8.jpg" alt="" /> */}
        <img src="Assets/Images/Seeding/Screenshot_9.jpg" alt="" />
        {/* <img src="Assets/Images/Seeding/Screenshot_10.jpg" alt="" /> */}
        <img src="Assets/Images/Seeding/Screenshot_11.jpg" alt="" />
        <img src="Assets/Images/Seeding/Screenshot_12.jpg" alt="" />
        <img src="Assets/Images/Seeding/1.jpg" alt="" />
        <img src="Assets/Images/Seeding/2.jpg" alt="" />
        <img src="Assets/Images/Seeding/3.jpg" alt="" />
        <img src="Assets/Images/Seeding/4.jpg" alt="" />
        <img src="Assets/Images/Seeding/5.jpg" alt="" />
        <img src="Assets/Images/Seeding/6.jpg" alt="" />
        <img src="Assets/Images/Seeding/7.jpg" alt="" />
        <img src="Assets/Images/Seeding/8.jpg" alt="" />
        <img src="Assets/Images/Seeding/9.jpg" alt="" />
        <img src="Assets/Images/Seeding/10.jpg" alt="" />
        <img src="Assets/Images/Seeding/11.jpg" alt="" />
      </div>
   

  
    
  );
};
export default ImgFeedback;
