import HookBlog from "../../Hook"
import { contentHeader, img } from "../../Content"
import { NummainContent } from "../../../../Components/Content/Report"
const ContentBlogNum11 = () => {
    const number = 11
    return (
        <>
        <HookBlog header={contentHeader[`num${number}`]} img={'https://images.giaoducthoidai.vn/1200x630/Uploaded/2024/mfnms/2022_07_30/a0-tr22-23-1599.jpg'} content={NummainContent[`num${number}`]} />
        </>
    )
}
export default ContentBlogNum11