import HookBlog from "../../Hook"
import { contentHeader, img } from "../../Content"
import { NummainContent } from "../../../../Components/Content/Report"
const ContentBlogNum4 = () => {
    const number = 4
    return (
        <>
        <HookBlog header={contentHeader[`num${number}`]} img={'https://storage.googleapis.com/youth-media/post-thumbnails/7d9f930c-61d5-4f51-9d39-47f6f74803b5.jpg'} content={NummainContent[`num${number}`]} />
        </>
    )
}
export default ContentBlogNum4