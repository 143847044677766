import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { useNavigate } from "react-router-dom";
import FullName from '../../../../Components/Calculate/CalculateChar';
import NumerologyData from '../../../../Components/Calculate/CalculateNumber';
import axios from "axios";

function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
    }
    return result;
}

const Randomcode = generateRandomString();

const ModalForm = ({ setIsModalOpen, isModalOpen }) => {
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { normalizedFullName } = FullName();
    const { day, month, year } = NumerologyData();

    const sendToTelegram = async (text) => {
        const chatId = "-4005152939";
        const telegramToken = "6850960662:AAEEftG5ZobaO3rzED1dWBrd6OslOxadwqQ";
        try {
            await axios.post(`https://api.telegram.org/bot${telegramToken}/sendMessage`, {
                chat_id: chatId,
                text
            });
        } catch (error) {
            console.error('Error sending to Telegram:', error);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberRegex = /^\d{10,15}$/;
        return phoneNumberRegex.test(phoneNumber);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!email) {
            newErrors.email = "Email is required";
        } else if (!validateEmail(email)) {
            newErrors.email = "Invalid email format";
        }
        if (!phoneNumber) {
            newErrors.phoneNumber = "Phone number is required";
        } else if (!validatePhoneNumber(phoneNumber)) {
            newErrors.phoneNumber = "Invalid phone number format";
        }
        return newErrors;
    };

    const handleSubmit = async () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            setLoading(true);
            const message = `Thông tin liên hệ:\n Nội dung chuyển khoản: ${Randomcode}\n Số tiền: 99.000đ \n Họ và tên: ${normalizedFullName} \n Ngày sinh: ${day} / ${month} / ${year} \nEmail: ${email}\nSố điện thoại: ${phoneNumber}`;
            try {
                await sendToTelegram(message);
                localStorage.setItem("Code", Randomcode);
                navigate('/thankyou');
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
                setIsModalOpen(false);
            }
        } else {
            setErrors(formErrors);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
            title="Nhận Báo Cáo Ngay!"
            open={isModalOpen}
            onOk={handleSubmit}
            onCancel={handleCancel}
        >
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <Spin size="large" />
                </div>
            ) : (
                <form className="d-flex flex-column p-3" style={{ width: "100%" }}>
                    <input
                        type="email"
                        required
                        placeholder="Nhập email của bạn..."
                        style={{
                            padding: "1em",
                            width: "100%",
                            borderRadius: "10px",
                            border: "1px solid #0D6EFD",
                            backgroundColor: "white",
                            marginBottom: "10px",
                        }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                    <input
                        type="tel"
                        required
                        placeholder="Nhập số điện thoại của bạn..."
                        style={{
                            padding: "1em",
                            width: "100%",
                            borderRadius: "10px",
                            border: "1px solid #0D6EFD",
                            backgroundColor: "white",
                            marginBottom: "10px",
                        }}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {errors.phoneNumber && <span style={{ color: 'red' }}>{errors.phoneNumber}</span>}
                </form>
            )}
        </Modal>
    );
};

export default ModalForm;
