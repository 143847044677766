import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "./Components/GolobalStyles";
import { FormValue } from "./Route";
import { ErrorBoundary } from "react-error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={
        <div>
          <div>Bạn chưa điền thông tin hoặc thông tin bạn điền chưa chính xác. vui lòng quay lại trang chủ!!!</div>
        </div>
      }
    >
      <GlobalStyles>
        <FormValue>
          <App />
        </FormValue>
      </GlobalStyles>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();
