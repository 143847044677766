import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PrimaryButton from '../../Components/Button';
import "./index.css"

// Hàm gửi thông báo đến Telegram
const sendToTelegram = async (text) => {
    const chatId = "-4005152939"; // Thay chatId bằng ID thật của bạn
    const telegramToken = "6850960662:AAEEftG5ZobaO3rzED1dWBrd6OslOxadwqQ"; // Thay bằng token thật của bạn

    try {
        await axios.post(`https://api.telegram.org/bot${telegramToken}/sendMessage`, {
            chat_id: chatId,
            text,
        });
    } catch (error) {
        console.error("Error sending to Telegram:", error);
    }
};

const CodeForm = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        setLoading(true);
        const correctCode = 'fg4xjcjlgo218';

        if (values.code === correctCode) {
            message.success('Mã đúng! Đang chuyển hướng đến file PDF...');

            // Gọi hàm gửi thông báo đến Telegram
            await sendToTelegram("Truy cập trang web thành công");

            setTimeout(() => {
                navigate('/report');
            }, 1000);
        } else {
            message.error('Mật khẩu sai');
            setLoading(false);
        }
    };

    const handleCopy = () => {
        // Sao chép nội dung vào clipboard
        navigator.clipboard.writeText('vệ sinh nhà xưởng')
            .then(() => {
                // Hiển thị thông báo đã copy
                message.success('Đã copy!');
            })
            .catch(() => {
                message.error('Copy thất bại');
            });
    };

    return (
        <div>
            <div className='bgs pt-3'>
                <div className='text-center'>
                    <h3>VUI LÒNG NHẬP MÃ ĐỂ ĐẾN FILE BÁO CÁO CHI TIẾT</h3>
                    <i>(Để tránh đối thủ copy nội dung, các bạn vui lòng bỏ ra khoản 30s để lấy mã và nhận file báo cáo hoàn toàn miễn phí nhé)</i>
                </div>
                <div className='bodyForm'>
                    <Form
                        name="codeForm"
                        onFinish={onFinish}
                        layout="vertical"
                    >
                        <Form.Item
                            label="Lấy mã và nhập vào ô dưới đây"
                            name="code"
                            rules={[{ required: true, message: 'Vui lòng nhập mã!' }]}
                        >
                            <Input placeholder='Nhập mã vừa tìm kiếm vào đây' />
                        </Form.Item>

                        <Form.Item>
                            <PrimaryButton primary small loading={loading}>
                                Nhấn vào đây để tiếp tục
                            </PrimaryButton>
                        </Form.Item>
                    </Form>
                    <h3>HƯỚNG DẪN LẤY MÃ VƯỢT LINK</h3>
                    <div>
                        <h5>Bước 1: Truy cập Google. Tìm từ khóa trên thanh tìm kiếm:</h5>
                        <div className='d-flex'>
                            <h5 className='textRed'>Vệ sinh nhà xưởng</h5>
                            <Button type="primary" onClick={handleCopy}>
                                Copy
                            </Button>
                        </div>
                        <h5>Bước 2: Trong kết quả tìm kiếm, kéo xuống bên dưới tìm website giống như hình <span>(Nếu không có trong trang thứ 1 nhấp vào xem thêm để hiển thị kết quả)</span></h5>
                        <img src="/Assets/Images/seo1.png" alt="" />
                        <h5>Bước 3: Kéo xuống bên dưới và lấy mã. <span>(Sau khi điền đúng mã, bạn sẽ được chuyển ngay đến trang báo cáo đầy đủ bằng PDF mà không cần làm thêm bước nào nửa)</span></h5>
                        <img src="/Assets/Images/seopass.png" alt="" />
                        <div className='formsmall'>
                            <Form
                                name="codeForm"
                                onFinish={onFinish}
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Lấy mã và nhập vào ô dưới đây"
                                    name="code"
                                    rules={[{ required: true, message: 'Vui lòng nhập mã!' }]}
                                >
                                    <Input placeholder='Nhập mã vừa tìm kiếm vào đây' />
                                </Form.Item>

                                <Form.Item>
                                    <PrimaryButton primary small loading={loading}>
                                        Nhấn vào đây để tiếp tục
                                    </PrimaryButton>
                                </Form.Item>
                            </Form>
                        </div>
                        <h5>Sau khi điền mã thành công. bạn sẽ nhận được file báo cáo đầy đủ bằng PDF mà không cần làm thêm bước nào nửa</h5>
                    </div>
                </div>
            </div>
            <div className='sublg'>
                <h3>Tại sao cần điền đúng mã để lấy mật khẩu</h3>
                <p>- Để tránh tình trạng đối thủ cạnh tranh không công bằng, dùng file miễn phí từ đội ngũ tracuuthansohoc để bán</p>
                <p>- Để duy trì kinh phí cho website tiếp tục hoạt động, chúng tôi cần lượt tương tác cho website chính của mình</p>
                <i>CHÚNG TA KHÔNG THỂ THAY ĐỔI HƯỚNG GIÓ, NHƯNG CHÚNG TA CÓ THỂ THAY ĐỔI HƯỚNG BUỒM</i>
            </div>
        </div>
    );
};

export default CodeForm;
