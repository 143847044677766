import "bootstrap/dist/css/bootstrap.css";
import './index.css';
import Circle from "../SectionCircle";

export default function Content() {
    return (
        <div>
        <div className="container flex-column align-items-center d-flex">
        <div className="row">
                {/* Card 1 */}
                <div className="col-md-6 col-lg-3 mb-4">
                    <div className="card">
                        <img src="../Assets/Images/a00.jpg" className="card-img-top" alt="Magic hands with sparkles" />
                        <div className="card-body">
                            <p className="card-text">Khai phá sứ mệnh và mục đích sống.</p>
                        </div>
                    </div>
                </div>
                {/* Card 2 */}
                <div className="col-md-6 col-lg-3 mb-4">
                    <div className="card">
                        <img src="../Assets/Images/a01.jpg" className="card-img-top" alt="Woman enjoying mist" />
                        <div className="card-body">
                            <p className="card-text">Phát huy điểm mạnh, khắc phục điểm yếu để hoàn thiện bản thân.</p>
                        </div>
                    </div>
                </div>
                {/* Card 3 */}
                <div className="col-md-6 col-lg-3 mb-4">
                    <div className="card">
                        <img src="../Assets/Images/a02.jpg" className="card-img-top" alt="Meditative figure with chakras" />
                        <div className="card-body">
                            <p className="card-text">Câu nối giữa con người hiện tại và năng lực tiềm ẩn.</p>
                        </div>
                    </div>
                </div>
                {/* Card 4 */}
                <div className="col-md-6 col-lg-3 mb-4">
                    <div className="card">
                        <img src="../Assets/Images/a03.jpg" className="card-img-top" alt="Woman holding a butterfly" />
                        <div className="card-body">
                            <p className="card-text">Thấu hiểu bản thân để làm chủ cuộc sống.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 content-wrapper">
                <h3>XEM THẦN SỐ HỌC PITAGO</h3>
                <h4>Thần số học là gì?</h4>
                <p>Thần số học (Numerology) hay còn được gọi là Nhân số học là bộ môn Huyền học nghiên cứu về sự tác động sóng rung của các con số với đời sống con người.</p>
                <p>Xem thần số học miễn phí theo tên và ngày sinh là bước đầu của hành trình thấu hiểu, khai mở tiềm năng thực sự của bản thân qua các con số.</p>
                <h4>Có bao nhiêu chỉ số thần số học?</h4>
                <p>Trong bộ môn thần số học Pitago có tất cả 26 chỉ số, trong đó có có 8 chỉ số chính:</p>
                <ul>
                    <p>- Chỉ số đường đời (số chủ đạo): Con số đại diện cho toàn bộ những đặc điểm, tính cách của một người bắt đầu từ khi sinh ra.</p>
                    <p>- Chỉ số linh hồn: Chỉ số thể hiện sự khao khát từ sâu bên trong mỗi con người.</p>
                    <p>- Chỉ số thái độ: Chỉ số thể hiện cách bạn phản ứng trước các vấn đề trong cuộc sống.</p>
                    <p>- Chỉ số sứ mệnh: Thể hiện cách để bạn đạt được mục tiêu lớn nhỏ trong cuộc đời.</p>
                    <p>- Chỉ số nhân cách: Chỉ số thể hiện sự phản hồi của người khác cảm thấy ở bạn qua các phản ứng, lời nói, việc làm</p>
                    <p>- <strong>Chỉ số năng lực tự nhiên</strong>: Chỉ số nói về năng khiếu bẩm sinh của bạn.</p>
                    <p>- <strong>Chỉ số trưởng thành</strong>: Thể hiện giá trị thực sự, đích đến cuối cùng của bạn.</p>
                    <p>- Chỉ số vượt khó: Chỉ số thể hiện cách bạn phản ứng khi gặp phải những thử thách.</p>
                    <p>- <strong>Chỉ số nợ nghiệp</strong>: Thể hiện những bài học cụ thể bạn phải học ở kiếp này vì đã không học ở kiếp trước.</p>
                    <p>- Biểu đồ sức mạnh: Biểu đồ cho thấy tổng quan năng lực của bản thân trong từng khía cạnh cụ thể.</p>
                    <p>- <strong>Biểu đồ kim tự tháp</strong>: Đại diện cuộc sống của con người trong những năm tháng trưởng thành.</p>
                </ul>
            </div>
            <Circle />
        </div>
            <div class="section-count">
            <div>
                <h5 class="title font2 text-center">Số người sử dụng sản phẩm</h5>
                <div class="d-flex box-use">
                    <div class="img img-sun">
                        <img src="/themes/thansohoc/asset/images/icon/sun.png" alt=""/>
                    </div>
                    <div class="use">
                        98,592+
                    </div>
                </div>
                <div class="mt-3 text-center text-white">
                    Đơn vị hàng đầu trong lĩnh vực phát triển công cụ thấu hiểu bản thân và dự báo thời vận bằng thần số học. Có nhiều năm kinh nghiệm hoạt động trong lĩnh vực tư vấn cá nhân, hỗ trợ định hướng giáo dục.
                </div>
            </div>
        </div>
        </div>
    );
}
