const contentHeader = {
    num1: 'Số chủ đạo 1: Hành trình của nhà lãnh đạo bẩm sinh',
    num2: 'Số chủ đạo 2: Sự cân bằng, hòa hợp và nhẹ nhàng',
    num3: 'Số chủ đạo 3: Cuộc chơi không ngừng, tinh thần tự do',
    num4: 'Số chủ đạo 4: Cẩn thận và trách nhiệm',
    num5: 'Số chủ đạo 5: Đường đời tự do và biến động',
    num6: 'Số chủ đạo 6: Tình yêu vô điều kiện và gia đình',
    num7: 'Số chủ đạo 7: Tri thức huyền bí và con đường độc hành',
    num8: 'Số chủ đạo 8: Sức mạnh của tiền tài và vật chất',
    num9: 'Số chủ đạo 9: Chủ nghĩa nhân đạo và trực giác nhạy bén',
    num11: 'Số chủ đạo 11: Trực giác vượt thời gian',
    num22: 'Số chủ đạo 22: Thiên tài và sự chi tiết tuyệt đối',
}

const img = {
    num1:'https://numology.com/img/Life-path-1.png',
    num2:'https://numology.com/img/Life-path-2.png',
    num3:'https://numology.com/img/Life-path-3.png',
    num4:'https://numology.com/img/Life-path-4.png',
    num5:'https://numology.com/img/Life-path-5.png',
    num6:'https://numology.com/img/Life-path-6.png',
    num7:'https://numology.com/img/Life-path-7.png',
    num8:'https://numology.com/img/Life-path-8.png',
    num9:'https://numology.com/img/Life-path-9.png',
    num11:'https://numology.com/img/Life-path-11.png',
    num22:'https://numology.com/img/Life-path-22.png',
}
export{contentHeader,img}