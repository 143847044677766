import Banner from "./Banner";
import Circle from "./SectionCircle";
import Form from "./Form";
import Welcom from "./Welcom";
import Content from "./content";
function Home() {
  return (
    <div>
      <Banner />
      {/* <Form /> */}
      {/* <Circle /> 
      <Welcom /> */}
      <div className="bg-fixed"><Content /></div>
    </div>
  );
}

export default Home;
