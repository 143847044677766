import HookBlog from "../../Hook"
import { contentHeader, img } from "../../Content"
import { NummainContent } from "../../../../Components/Content/Report"
const ContentBlogNum8 = () => {
    const number = 8
    return (
        <>
        <HookBlog header={contentHeader[`num${number}`]} img={'https://gcs.tripi.vn/public-tripi/tripi-feed/img/473725Hlb/bai-van-nghi-luan-ve-moi-quan-he-giua-tien-tai-va-hanh-phuc-so-11-606528.jpg'} content={NummainContent[`num${number}`]} />
        </>
    )
}
export default ContentBlogNum8